import React, { useState } from 'react';
import { Box } from '@mui/material';
import Equipes from './Equipes';
import DadosIniciais from './DadosIniciais';
import PageTitle from '../components/PageTitle';
import { CustomTabs, CustomTab } from '../components/CustomTabs';

const Funcionarios = () => <div>Conteúdo de Funcionários</div>;
const MetasGlobais = () => <div>Conteúdo de Metas Globais</div>;
const MetasEspecificas = () => <div>Conteúdo de Metas Específicas</div>;

const Premissas: React.FC = () => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  return (
    <Box sx={{ margin: 'auto' }}>
      <PageTitle
        title='PREMISSAS'
        message='Esta é a parte mais importante da planilha, pois é aqui que você definirá as premissas necessárias que ajudarão a gerar informações sobre suas estratégias.'
      />
      <CustomTabs
        value={activeTab}
        onChange={handleTabChange}
        textColor="primary"
        indicatorColor="primary"
        variant="fullWidth"
      >
        <CustomTab tabTitle="Dados iniciais" content={<DadosIniciais />} />
        <CustomTab tabTitle="Equipes" content={<Equipes />} />
        <CustomTab tabTitle="Funcionários" content={<Funcionarios />} />
        <CustomTab tabTitle="Metas globais" content={<MetasGlobais />} />
        <CustomTab tabTitle="Metas específicas" content={<MetasEspecificas />} />
      </CustomTabs>
    </Box>
  );
};

export default Premissas;