import React from 'react';
import { Box, Tabs, Tab, Typography } from '@mui/material';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel: React.FC<TabPanelProps> = ({ children, value, index, ...other }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

interface CustomTabProps {
  tabTitle: string;
  content: React.ReactNode;
}

interface CustomTabsProps {
  value: number;
  onChange: (event: React.SyntheticEvent, newValue: number) => void;
  textColor?: 'primary' | 'secondary';
  indicatorColor?: 'primary' | 'secondary';
  variant?: 'standard' | 'scrollable' | 'fullWidth';
  children: React.ReactElement<CustomTabProps>[];
}

export const CustomTab: React.FC<CustomTabProps> = ({ tabTitle, content }) => null;

export const CustomTabs: React.FC<CustomTabsProps> = ({
  value,
  onChange,
  textColor = 'primary',
  indicatorColor = 'primary',
  variant = 'fullWidth',
  children,
}) => {
  return (
    <Box sx={{ width: '100%' }}>
      <Tabs
        value={value}
        onChange={onChange}
        textColor={textColor}
        indicatorColor={indicatorColor}
        variant={variant}
      >
        {React.Children.map(children, (child, index) =>
          React.isValidElement(child) ? (
            <Tab label={child.props.tabTitle} id={`tab-${index}`} aria-controls={`tabpanel-${index}`} />
          ) : null
        )}
      </Tabs>

      {React.Children.map(children, (child, index) =>
        React.isValidElement(child) ? (
          <TabPanel value={value} index={index}>
            {child.props.content}
          </TabPanel>
        ) : null
      )}
    </Box>
  );
};