import { addDoc, collection, doc, DocumentData, getDoc, getDocs, QuerySnapshot, Timestamp } from "@firebase/firestore";
import { db } from "../firebaseConfig";

export default class AbstractService<T> {

    private collection: string;

    constructor(collection: string) {
        this.collection = collection;
    }

    async getById(id: string): Promise<T | null> {
      const docRef = doc(db, this.collection, id);
      const docSnapshot = await getDoc(docRef);
      if (docSnapshot.exists()) {
        return { id: docSnapshot.id, ...docSnapshot.data() } as T;
      } else {
        return null;
      }
    }

    async getAll(): Promise<T[]> {
      const collectionRef = collection(db, this.collection);
      const querySnapshot: QuerySnapshot<DocumentData> = await getDocs(collectionRef);
      return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() } as T));
    }
    
    async save(entity: T) {
        const vendasCollection = collection(db, this.collection);     
        const docRef = await addDoc(vendasCollection, {
          ...entity,
          data: Timestamp.fromDate(new Date())
        });
        return {
          ...entity,
          id: docRef.id // Inclui o ID da venda recém-criada
        };
    }   
}