import React, { useState } from 'react';
import InfoHeader from '../components/InfoHeader';
import { Box, TextField, Grid, Paper, Button, List, ListItem, ListItemText, IconButton, Card } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

const Equipes: React.FC = () => {
  const [equipes, setEquipes] = useState<string[]>([]);
  const [showInput, setShowInput] = useState(false);
  const [newEquipe, setNewEquipe] = useState('');

  const handleAddEquipe = () => {
    if (newEquipe.trim()) {
      setEquipes([...equipes, newEquipe]);
      setNewEquipe('');
      setShowInput(false);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewEquipe(e.target.value);
  };

  const handleDeleteEquipe = (indexToDelete: number) => {
    const updatedEquipes = equipes.filter((_, index) => index !== indexToDelete);
    setEquipes(updatedEquipes);
  };

  return (
    <Box>
      <InfoHeader 
        title='Equipes'
        message='Cadastre as equipes existentes na sua empresa. Este cadastro será utilizado no controle e gestão dos seus planos de ação.'
        isImportant={true}
      />
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <Paper elevation={3} sx={{ padding: 2 }}>
            <Card
              color='secondary'
              sx={{
                padding: 1,
                textAlign: 'center',
                backgroundColor: (theme) => theme.palette.secondary.main,
              }}
            >
              Cadastro de equipes
            </Card>

            <List sx={{ marginTop: 2 }}>
              {equipes.map((equipe, index) => (
                <ListItem
                  key={index}
                  secondaryAction={
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => handleDeleteEquipe(index)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  }
                >
                  <ListItemText primary={equipe} />
                </ListItem>
              ))}
            </List>
            {showInput && (
              <Box sx={{ marginTop: 2 }}>
                <TextField
                  fullWidth
                  label="Digite o nome da equipe"
                  value={newEquipe}
                  onChange={handleInputChange}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                      handleAddEquipe();
                    }
                  }}
                  autoFocus
                />
                <Button
                  variant="contained"
                  sx={{ marginTop: 2 }}
                  fullWidth
                  onClick={handleAddEquipe}
                >
                  Adicionar
                </Button>
              </Box>
            )}
            {!showInput && (
              <Button
                variant="contained"
                fullWidth
                sx={{
                  marginTop: 2
                }}
                onClick={() => setShowInput(true)}
              >
                + Adicionar equipe
              </Button>
            )}
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Equipes;
