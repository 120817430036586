import React, { useState } from 'react';
import { Box } from '@mui/material';
import PageTitle from '../components/PageTitle';
import { CustomTabs, CustomTab } from '../components/CustomTabs';

const MetasEspecificas = () => <div>Conteúdo de Metas Específicas</div>;
const MetasGlobais = () => <div>Conteúdo de Metas Globais</div>;
const PlanosDeAcao = () => <div>Conteúdo de Planos de Ação</div>;

const ResultadosConsolidados: React.FC = () => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  return (
    <Box sx={{ margin: 'auto' }}>
      <PageTitle
        title='RESULTADOS CONSOLIDADOS'
        message='Tenha uma visão geral da sua estratégia e como ela vem sendo implementada. A partir daqui, você poderá ter insights sobre as ações que devem ser implementadas ou ajustadas.'
      />
      <CustomTabs
        value={activeTab}
        onChange={handleTabChange}
        textColor="primary"
        indicatorColor="primary"
        variant="fullWidth"
      >
        <CustomTab tabTitle="Metas específicas" content={<MetasEspecificas />} />
        <CustomTab tabTitle="Metas globais" content={<MetasGlobais />} />
        <CustomTab tabTitle="Planos de ação" content={<PlanosDeAcao />} />
      </CustomTabs>
    </Box>
  );
};

export default ResultadosConsolidados;