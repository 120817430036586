import React from 'react';
import { Box, Typography } from '@mui/material';

interface InfoHeaderProps {
  title: string;
  message: string;
  isImportant: boolean;
}

const InfoHeader: React.FC<InfoHeaderProps> = ({ title, message, isImportant }) => {
  return (
    <Box
      sx={{
        maxWidth: '100%',
        padding: '0px 0px 20px 0px',
        margin: '8px 0',
      }}
    >
      <Typography
        variant="h5"
        component="h2"
        sx={{
          fontWeight: 'bold',
          textAlign: 'left',
          marginBottom: '8px',
        }}
      >
        {title}
      </Typography>
      <Typography
        component="p"
        sx={{
          textAlign: 'left',
          fontSize: '1rem',
          lineHeight: 1.6,
        }}
      >
        {isImportant && (
          <span style={{ color: 'red', fontWeight: 'bold', marginRight: '4px' }}>
            Importante:
          </span>
        )}
        {message}
      </Typography>
    </Box>
  );
};

export default InfoHeader;