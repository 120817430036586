import React from 'react';
import { Box, Typography } from '@mui/material';

interface PageTitleProps {
  title: string;
  message: string;
}

const PageTitle: React.FC<PageTitleProps> = ({ title, message }) => {
  return (
    <Box display="flex" alignItems="center" sx={{ justifyContent: 'space-between' }}>
        <Typography variant="h6" sx={{ marginRight: 2, width: '30%' }}>
            {title}
        </Typography>
        <Typography sx={{ fontSize: '0.9rem', color: 'gray', width: '50%' }}>
            {message}
        </Typography>
      </Box>
  );
};

export default PageTitle;