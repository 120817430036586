import React, { useState } from 'react';
import { Box } from '@mui/material';
import PageTitle from '../components/PageTitle';
import { CustomTabs, CustomTab } from '../components/CustomTabs';

const Financas = () => <div>Conteúdo de Finanças</div>;
const Vendas = () => <div>Conteúdo de Vendas</div>;
const ProcessosProducao = () => <div>Conteúdo de Processos / Produção</div>;
const Pessoas = () => <div>Conteúdo de Pessoas</div>;

const PlanoDeAcao: React.FC = () => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  return (
    <Box sx={{ margin: 'auto' }}>
      <PageTitle
        title='PLANO DE AÇÃO'
        message='Com sua estratégia definida, chegou a hora de traçar um plano de ação para que seus objetivos sejam efetivamente algançados.'
      />
      <CustomTabs
        value={activeTab}
        onChange={handleTabChange}
        textColor="primary"
        indicatorColor="primary"
        variant="fullWidth"
      >
        <CustomTab tabTitle="Finanças" content={<Financas />} />
        <CustomTab tabTitle="Vendas" content={<Vendas />} />
        <CustomTab tabTitle="Processos / Produção" content={<ProcessosProducao />} />
        <CustomTab tabTitle="Pessoas" content={<Pessoas />} />
      </CustomTabs>
    </Box>
  );
};

export default PlanoDeAcao;