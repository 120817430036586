// src/App.tsx

import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import LoginPage from "./LoginPage";
import AppLayout from "./AppLayout";
import { ThemeContextProvider } from "./components/theme/ThemeContext";
import Premissas from "./pages/Premissas";
import PlanoDeAcao from "./pages/PlanoDeAcao";
import ResultadosConsolidados from "./pages/ResultadosConsolidados";
import Graficos from "./pages/Graficos";
import Estrategia from "./pages/Estrategia";
import AlertasDicas from "./pages/AlertasDicas";
import Relatorios from "./pages/Relatorios";

const App: React.FC = () => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);

  useEffect(() => {
    const authStatus = localStorage.getItem("isAuthenticated") === "true";
    setIsAuthenticated(authStatus);
  }, []);

  return (
    <ThemeContextProvider>
      <Router>
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          
          {/* Rota principal protegida */}
          <Route
            path="/"
            element={
              isAuthenticated ? (
                <AppLayout isAuthenticated={isAuthenticated} />
              ) : (
                <Navigate to="/login" /> // Redireciona para a página de login caso não esteja autenticado
              )
            }
          >
            <Route path="/premissas" element={<Premissas />} />
            <Route path="/estrategia" element={<Estrategia />} />
            <Route path="/plano-de-acao" element={<PlanoDeAcao />} />
            <Route path="/resultados-consolidados" element={<ResultadosConsolidados />} />
            <Route path="/alertas-e-dicas" element={<AlertasDicas />} />
            <Route path="/graficos" element={<Graficos />} />
            <Route path="/relatorios" element={<Relatorios />} />
          </Route>
        </Routes>
      </Router>
    </ThemeContextProvider>
  );
};

export default App;